.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-family: "Raleway", sans-serif;
}

.NavigationItem a {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 12pt;
  transition: border-bottom 0.3s, color 0.3s;
}

.NavigationItem a:hover {
  color: #cca43b;
  cursor: pointer;
}

@media (min-width: 810px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .NavigationItem a {
    color: white;
    height: 100%;
    padding: 12px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover {
    border-bottom: 4px solid #cca43b;
    color: #cca43b;
    cursor: pointer;
  }
}
