.wrapper {
  min-height: 75vh;
  padding-top: 25vh;
}

.header {
  font-size: 40pt;
  font-weight: 700;
  letter-spacing: 3px;
  color: #000000;
}

.body {
  margin-top: 50px;
}

.para {
  line-height: 1.5;
}

.resumeButton {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.resumeButton a {
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #ffffff;
  border: 1px solid #cca43b;
  color: #cca43b;
  border-radius: 3px;
  transition: 0.3s;
  letter-spacing: 3px;
  text-decoration: none;
}

.resumeButton a:hover {
  background: #cca43b;
  color: #ffffff;
}

.icons {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20pt;
  text-align: center;
  align-items: center;
}

.icons a {
  display: contents;
  white-space: none;
  text-decoration: none;
  color: #000000;
  transition: color 0.3s;
}

.icons a:hover {
  color: #cca43b;
}

.icon {
  margin: 20px;
}

@media (max-width: 1000px) {
  .icons {
    top: 100%;
    right: 50%;
    position: absolute;
    flex-direction: row;
    transform: translate(0%, 0%);
    transform: translateX(50%);
  }
}

@media (max-width: 810px) {
  .header {
    font-size: 30pt;
  }
}
