.wrapper {
  font-family: "Raleway", sans-serif;
  font-size: 12pt;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  color: #7f7f7f;
  font-weight: 300;
}

.contentWrapper {
  padding-left: 20vw;
  padding-right: 20vw;
}

@media (max-width: 1000px) {
  .contentWrapper {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.loadingWrapper {
  width: 100%;
  height: 100%;
}

.logoWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  opacity: 0;
  display: inline-block;
  animation: blink 2.5s ease-out infinite alternate;
}

.logoWrapper img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
